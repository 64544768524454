<template>
  <v-overlay color="white" v-model="open">
    <vue-loaders name="ball-scale-ripple-multiple" color="#306bfd" scale="2"></vue-loaders>
  </v-overlay>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['loading']),
  },
  watch: {
    loading() {
      this.open = this.loading
    },
  },
  data: () => ({
    open: null,
  }),
  props: {
    text: {
      default: 'Loading',
      type: String,
    },
  },
}
</script>

<style></style>
