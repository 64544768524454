<template>
  <v-app>
    <TheHeader v-if="logged" :type="false" />
    <v-main>
      <!-- <v-btn depressed icon v-if="logged" @click="menu()" dark><v-icon dark >mdi-menu</v-icon></v-btn> -->
      <router-view></router-view>
    </v-main>
    <TheError />
    <TheLoading />
    <TheFooter />
    <TheCookie />
  </v-app>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'
import TheError from '@/components/TheError'
import TheCookie from '@/components/TheCookie'
import TheLoading from '@/components/TheLoading'

import { mapState } from 'vuex'
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    TheCookie,
    TheError,
    TheFooter,
    TheHeader,
    TheLoading,
  },
  computed: {
    ...mapState(['logged']),
  },
  data: () => ({}),
  methods: {
    loadMenus() {
      const url = `${baseApiUrl}/menu/header`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch('setMenuInfo', res.data)
        })
        .catch(showError)
    },     
    
    isLogged() {
    const user = JSON.parse(localStorage.getItem('user'))
    const route = window.location.href
      if(!user){
        this.$store.dispatch('setUserInfo', { user: null })
        if(!route.includes('/rescuePassword') && !route.includes('/politica-de-privacidade') && !route.includes('/termos-de-uso')){
          this.$router.push({ path: '/login'  })
        }
        return
      } else {
        this.$store.dispatch('setUserInfo', user)
        this.loadMenus()
      }
    },

    menu() {
      this.$store.dispatch('setHamburgerInfo', true)
    },
  },
  mounted() {
    this.isLogged()
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

html {
  /* font-family: 'Montserrat', sans-serif !important; */
}
#app {
  /* font-family: 'Montserrat', sans-serif !important; */
  background: #eef3f7;
  background: #eef3f7
    linear-gradient(180deg, rgba(85, 87, 198, 1) 0%, rgba(48, 107, 253, 1) 100%)
    repeat-x;
  background-size: 100% 300px;
  color: #2d3446 !important;
}

.text-color-1 {
  color: #0288d1 !important;
}

.text-color-2 {
  color: #90a4ae !important;
}

.sd-lt {
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05) !important;
}
.rd-lt {
  -webkit-border-radius: 20px !important;
  border-radius: 20px !important;
}

.v-sheet.v-card {
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.v-btn--is-elevated {
  /* -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05); */
  border-radius: 10px;
}

.button-overall {
  position: fixed;
  z-index: 1000;
  right: 20px;
  bottom: 40px;
}

.small-text {
  font-size: 0.8em;
}

.v-dialog {
  border-radius: 20px;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

*::-webkit-scrollbar {
  display: none;
}

</style>
