import store from './plugins/store'

// export const baseApiUrl = 'http://localhost:4000' //
export const baseApiUrl = process.env.VUE_APP_BASE_API_URL

export function showError(e) {
  store.dispatch('setLoadingInfo', false)
  let text = ''
  if (e && e.response && e.response.data && e.response.data.error) {
    text = e.response.data.error.message
  } else if (e && e.response.data.message) {
    const t = e.response.data.message
    if (Array.isArray(t)) {
      for (let i = 0; i < t.length; i++) {
        const element = t[i]
        text += element + '<br />'
      }
    } else {
      text = e.response.data.message
    }
  } else if (typeof e === 'string') {
    text = e
  } else {
    text = 'Algo deu errado.'
  }
  if (text) {
    store.dispatch('setErrorInfo', {
      open: true,
      text: text,
      button: 'Ok!',
    })
  }
}

export default { baseApiUrl }
