import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import store from "./plugins/store"
import VueLoaders from "vue-loaders"
import "vue-loaders/dist/vue-loaders.css"

import "./plugins/msg"
import "./plugins/axios"
import "./plugins/filters.js"
import rules from "./plugins/rules.js"
import money from 'v-money'


Vue.use(money, {precision: 4})
Vue.config.productionTip = false

Vue.use(VueLoaders)
Vue.mixin(rules)

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
