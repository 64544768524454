import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatMoney', (number) => {
  if (number < 100000) {
    return `R$ ${(number / 100).toFixed(2).replace('.', ',')}`
  }
  if (number >= 100000 && number < 99999999) {
    return `R$ ${(number / 100000).toFixed(2).replace('.', ',')} K`
  }
  if (number >= 99999999) {
    return `R$ ${(number / 10000000).toFixed(2).replace('.', ',')} M`
  }
})

export function unformatMoney(value) {
  if (!value) return ''
  return parseFloat(value.replaceAll('.', '').replaceAll(',', '.'))
}

Vue.filter('formatMoneyS1', (number) => {
  return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 3})
})


Vue.filter('formatFullMoney', (number) => {
  return `R$ ${(number / 100).toFixed(2).replace('.', ',')}`
})

Vue.filter('formatFreight', (value) => {
  if (!value) return ''
  return value.toFixed(2)
})

Vue.filter('formatDate', (date) => {
  if (!date) return ''
  return date
    .slice(0, 10)
    .split('-')
    .reverse()
    .join('/')
})

Vue.filter('formatPhone', (phone) => {
  if (!phone) return ''
  return phone.replace(/(\w{3})(\d{2})(\d{5})(\d{4})/g, '$1($2) $3-$4')
})

Vue.filter('formatDate2', (date) => {
  if (!date) return ''
  return new Date(date).toLocaleDateString('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  })
})

Vue.filter('formatDate3', (date) => {
  if (!date) return ''
  return  moment(new Date(date)).format('DD/MM/YYYY')
})

Vue.filter('formatFullDate', (date) => {
  if (!date) return ''
  const time = date
    .slice(11, 19)
    .split('-')
    .join(':')
  return (
    date
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/') +
    ' ' +
    time
  )
})

Vue.filter('uppercase', (text) => {
  return text.toUpperCase()
})

Vue.filter('capitalize', (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1)
})

Vue.filter('formatTpDoc', (tpDoc) => {
  if (tpDoc === 1) return 'CNPJ'
  else return 'CPF'
})

Vue.filter('formatDoc', (doc, item) => {
  if (doc === undefined) return ''
  if (item.doc_tp_seller === 1) {
    return doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
  } else {
    return doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  }
})

Vue.filter('formatCPF', (cpf) => {
  if (cpf === undefined) return ''
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
})

Vue.filter('formatPriceTag', (tag) => {
  if (tag.search('saldao')) {
    return 'Saldão'
  } else if (tag.search('colaboradores')) {
    return 'Preço para Colaboradores'
  } else {
    return ''
  }
})

Vue.filter('formatRisk', (risk) => {
  if (!risk) {
    return ''
  }
  if (risk <= 0.12) {
    return 'Risco Baixo'
  }
  if (risk > 0.12 && risk <= 0.32) {
    return 'Risco Médio'
  }
  return 'Risco Alto'
})

const types = [
  { text: 'Coleta', value: 1 },
  { text: 'Uso/Finalidade', value: 2 },
  { text: 'Compartilhamento', value: 3 },
  { text: 'Retenção/Armazenamento', value: 4 },
  { text: 'Descarte', value: 5 }
]

Vue.filter('translateType', (type) => {
  for (let i = 0; i < types.length; i++) {
    const element = types[i]
    if (parseInt(element.value) === type) {
      return element.text
    }
  }
  return ''
})
