export default {
  methods: {
    requiredRule(value) {
      if (!value) {
        return 'O campo é obrigatório'
      }
      if (Array.isArray(value) && value.length === 0) {
        return 'É necessario selecionar pelo menos um valor'
      }
      return true
    },
    spaceRule(v) {
      if (v) {
        const spaceRegex = /^\s|\s$/
        if (spaceRegex.test(v)) {
          return 'O nome não pode começar ou terminar com um espaço.'
        }
        return true
      }
      return true
    },
    dateBirthRule(data) {
      const regex = /^\d{2}\/\d{2}\/\d{4}$/
      if (!regex.test(data)) {
        return false
      }

      const [dia, mes, ano] = data.split('/')

      if (parseInt(ano) <= 1900) {
        return 'Ano inválido'
      }

      const dateObj = new Date(`${mes}/${dia}/${ano}`)

      if (dateObj.getFullYear() != ano || dateObj.getMonth() + 1 != mes || dateObj.getDate() != dia) {
        return 'Data inválida'
      }

      return true
    },
    validarDataVencimentoCNH(dataVencimento) {
      const regex = /^\d{2}\/\d{2}\/\d{4}$/
      if (!regex.test(dataVencimento)) {
        return false
      }

      const [dia, mes, ano] = dataVencimento.split('/')

      const hoje = new Date()
      const dataVencimentoObj = new Date(ano, mes - 1, dia)

      if (dataVencimentoObj.getTime() <= hoje.getTime()) {
        return 'Data inválida'
      }

      const dataMaxima = new Date()
      dataMaxima.setFullYear(dataMaxima.getFullYear() + 10)

      if (dataVencimentoObj.getTime() > dataMaxima.getTime()) {
        return 'Data inválida'
      }

      return true
    },
    validarCPF(cpf) {
      cpf = cpf.replace(/\D/g, '')

      if (cpf.length !== 11) {
        return 'CPF inválido'
      }

      let soma = 0
      let resto
      for (let i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i)
      }
      resto = (soma * 10) % 11
      let digitoVerificador1 = resto === 10 || resto === 11 ? 0 : resto

      soma = 0
      for (let i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
      }
      resto = (soma * 10) % 11
      let digitoVerificador2 = resto === 10 || resto === 11 ? 0 : resto

      if (
        digitoVerificador1 !== parseInt(cpf.substring(9, 10)) ||
        digitoVerificador2 !== parseInt(cpf.substring(10, 11))
      ) {
        return 'CPF inválido'
      }

      return true
    },

    validarCNPJ(cnpj) {
      cnpj = cnpj.replace(/\D/g, '')

      if (cnpj.length !== 14) {
        return 'CNPJ inválido'
      }

      let soma = 0
      let peso = 2

      // Verifica o primeiro dígito verificador
      for (let i = 11; i >= 0; i--) {
        soma += parseInt(cnpj.charAt(i)) * peso
        peso++
        if (peso === 10) {
          peso = 2
        }
      }

      // Verificar se o CNPJ consiste apenas de dígitos repetidos
      if (/^(\d)\1*$/.test(cnpj)) {
        return 'CNPJ inválido';
      }

      let resto = soma % 11
      let digitoVerificador1 = resto < 2 ? 0 : 11 - resto

      if (digitoVerificador1 !== parseInt(cnpj.charAt(12))) {
        return 'CNPJ inválido'
      }

      soma = 0
      peso = 2

      // Verifica o segundo dígito verificador
      for (let i = 12; i >= 0; i--) {
        soma += parseInt(cnpj.charAt(i)) * peso
        peso++
        if (peso === 10) {
          peso = 2
        }
      }

      resto = soma % 11
      let digitoVerificador2 = resto < 2 ? 0 : 11 - resto

      if (digitoVerificador2 !== parseInt(cnpj.charAt(13))) {
        return 'CNPJ inválido'
      }

      return true
    },

    validarRG(rg) {
      rg = rg.replace(/[^\d]/g, '')

      if (rg.length !== 9) {
        return 'RG inválido'
      }

      const pesos = [2, 3, 4, 5, 6, 7, 8, 9]
      let soma = 0

      for (let i = 0; i < 8; i++) {
        soma += parseInt(rg.charAt(i)) * pesos[i]
      }

      const resto = soma % 11
      const digitoVerificador = resto < 2 ? 0 : 11 - resto

      if (digitoVerificador !== parseInt(rg.charAt(8))) {
        return 'RG inválido'
      }

      return true
    },
    validarCNH(cnh) {
      const regex = /^[\d]{11}$/
      if (regex.test(cnh)) {
        return true
      } else {
        return 'CNH incorreta'
      }
    },
    tipoCNH(valor) {
      const regex = /^[A-Za-z]{1,4}$/
      if (regex.test(valor)) {
        return true
      } else {
        return 'Tipo de CNH inválida'
      }
    },
    validarTelefone(telefone) {
      const regex = /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/
      if (regex.test(telefone)) {
        return true
      } else {
        return 'Telefone inválido'
      }
    },
    validarEmail(email) {
      const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
      if (regex.test(email)) {
        return true
      } else {
        return 'E-mail inválido'
      }
    },
    validarCEP(cep) {
      const regex = /^\d{5}-?\d{3}$/
      if (regex.test(cep)) {
        return true
      } else {
        return 'CEP inválido'
      }
    },

    passwordRule(senha) {
      
      if (senha.length < 8 || senha.length > 20) {
        return 'Sua senha deve conter entre 8 a 20 caracteres'
      }
    
      if (!/[A-Z]/.test(senha)) {
        return 'Sua senha deve conter 1 letra maiúscula'
      }
    
      if (!/[a-z]/.test(senha)) {
        return 'Sua senha deve conter pelo menos 1 letra minúscula'
      }
    
      if (!/\d/.test(senha)) {
        return 'Sua senha deve conter pelo menos 1 número'
      }
    
      if (!/[!@#$%^&*()_+{}:;<>,.?~\\/-]/.test(senha)) {
        return 'Sua senha deve conter pelo menos 1 caractere especial';
      }    
      return true;
    }    
  },
}
