import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logged: false,
    user: null,
    error: null,
    option: null,
    loading: false,
    hamburger: false,
    menu: [],
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
      if (payload) {
        axios.defaults.headers.common.Authorization = state.user.token
        state.logged = true
      } else {
        delete axios.defaults.headers.common.Authorization
        state.logged = false
      }
    },
    setError(state, payload) {
      state.error = payload
    },
    setOption(state, payload) {
      state.option = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setHamburger(state, payload) {
      console.log(payload)
      state.hamburger = !state.hamburger
    },
    setMenu(state, payload) {
      state.menu = payload
    },
  },
  actions: {
    setUserInfo(context, payload) {
      this.commit('setUser', payload)
    },
    setErrorInfo(context, payload) {
      this.commit('setError', payload)
    },
    setOptionInfo(context, payload) {
      this.commit('setOption', payload)
    },
    setLoadingInfo(context, payload) {
      this.commit('setLoading', payload)
    },
    setHamburgerInfo(context, payload) {
      this.commit('setHamburger', payload)
    },
    setMenuInfo(context, payload) {
      this.commit('setMenu', payload)
    },
  },
})
