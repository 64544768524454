<template>
  <v-dialog
    v-if="error"
    v-model="error"
    max-width="600px"
    overlay-color="white"
  >
    <template>
      <v-card class="pa-5 text-center">
        <div class="text-right">
          <v-icon small color="primary" @click="close()">mdi-close</v-icon>
        </div>
        <p v-if="!error.text" class="mb-5">Ops, algo deu errado :/</p>
        <p v-if="error.text" class="mb-5">{{ error.title }}</p>
        <p v-if="error.text" v-html="error.text" class="mb-5"></p>
        <div v-if="!Array.isArray(error.button)">
          <v-btn
            dark
            color="primary"
            block
            depressed
            x-large
            @click="close()"
          >
            <span v-if="error.button">{{ error.button }}</span>
            <span v-if="!error.button">Ok</span>
          </v-btn>
        </div>
        <div v-if="Array.isArray(error.button)">
          <v-row>
            <v-col v-for="(item, i) in error.button" :key="i">
              <v-btn
                dark
                color="primary"
                block
                depressed
                :outlined="item.outlined"
                x-large
                @click="setOptionInfo(i)"
              >
                {{ item.text }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["error"]),
  },
  watch: {
  },
  data: () => ({}),
  methods: {
    close() {
      if (this.error.route && !this.error.target) {
        this.$router.push(this.error.route);
      }
      if (this.error.route && this.error.target) {
        window.open(this.error.route, this.error.target)
      }
      this.$store.dispatch("setErrorInfo", null);
    },
    go() {
      if (this.error.route) {
        this.$router.push(this.error.route);
      }
    },
    setOptionInfo(i){
      this.$store.dispatch("setOptionInfo", i);
      this.close()
    }
  },
};
</script>

<style>
.rounded-un{
  border-radius: 15px !important;
}
</style>