<template>
  <v-navigation-drawer color="white" clipped :mini-variant="hamburger" app>
    <div :class="hamburger ? 'px-0 py-5 text-center' : 'px-10 py-5'">
      <router-link to="/" v-if="!hamburger"
        ><v-img src="@/assets/logo.png" height="42px" />
      </router-link>
      <router-link to="/" v-if="hamburger"
        ><v-img
          src="@/assets/iconlgpd.png"
          contain
          width="100%"
          height="32px"
        />
      </router-link>
    </div>
    <!-- {{ menus }} -->
    <v-divider class="mx-5"></v-divider>
    <div class="text-center py-5" v-if="!hamburger">
      <h3>{{ user.username }}</h3>
      <h5>{{ user.company.name }}</h5>
    </div>
    <v-divider class="mx-5 mb-5"></v-divider>
    <v-list
      v-if="menu2 && menu2.length > 0"
      dense
      :class="hamburger ? 'px-0' : 'px-2'"
      rounded
    >
      <v-list-group
        v-for="(item, i) in userMenu"
        :key="i"
        :prepend-icon="item.icon"
        color="blue darken-3"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </template>
        <v-list>
          <v-list-item
            color="primary"
            v-for="(menuItem, index) in item.list"
            :key="index"
            :to="menuItem.route"
          >
            <v-list-item-icon v-if="menuItem.icon">
              <v-icon>{{ menuItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list-group>
    </v-list>
    <v-divider class="mx-5 mb-5"></v-divider>
    <template v-slot:append>
      <v-list dense rounded :class="hamburger ? 'px-0' : 'px-2'">
        <v-list-item @click="logoff()">
          <v-list-item-icon>
            <v-icon>mdi-key</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Sair </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios"
import { mapState } from "vuex"
// import TheToolTip from '@/components/TheToolTip'

export default {
  name: "TheHeader",
  components: {
    // TheToolTip,
  },
  props: {
    type: Boolean,
  },
  computed: {
    ...mapState(["user", "hamburger", "menu"]),
    userMenu() {
      return this.checkMenu()
    },
  },
  data: () => ({
    on: false,
    menu2: [
      {
        name: "Dados",
        icon: "mdi-file-edit-outline",
        list: [
          { title: "Tipos de Dados", route: "/data", icon: "mdi-database" },
          {
            title: "Titulares de Dados",
            route: "/data-holders",
            icon: "mdi-database-settings",
          },
          {
            title: "Sensibilidade de Dados",
            route: "/datasensitivity",
            icon: "mdi-database-eye",
          },
        ],
        level: [0, 1, 2],
      },

      {
        name: "Empresa",
        icon: "mdi-office-building-outline",
        list: [
          { title: "Setores", route: "/sector", icon: "mdi-human-male-height" },
          { title: "Cargos", route: "/position", icon: "mdi-account-network" },
          {
            title: "Pessoas",
            route: "/people",
            icon: "mdi-account-reactivate",
          },
          {
            title: "Fornecedores",
            route: "/fornecedores",
            icon: "mdi-office-building-plus",
          },
          {
            title: "Softwares",
            route: "/software",
            icon: "mdi-monitor-dashboard",
          },
          { title: "Repositórios", route: "/local", icon: "mdi-google-maps" },
        ],
        level: [0, 1, 2],
      },

      {
        name: "Processos",
        icon: "mdi-check-network-outline",
        list: [
          { title: "Processos", route: "/process", icon: "mdi-run-fast" },
          {
            title: "Vulnerabilidades",
            route: "/vulnerability",
            icon: "mdi-shield-lock-outline",
          },
          {
            title: "Plano de Ação",
            route: "/plan",
            icon: "mdi-file-certificate-outline",
          },
        ],
        level: [0, 1, 2],
      },

      {
        name: "Relatórios",
        icon: "mdi-chart-box-outline",
        list: [
          { title: "Dashboard", route: "/", icon: "mdi mdi-monitor-dashboard" },
          { title: "Geral", route: "/reports/general", icon: "mdi-chart-tree" },
          {
            title: "Organograma",
            route: "/reports/company-organogram",
            icon: "mdi-sitemap",
          },
          {
            title: "Classificação de Riscos",
            route: "/reports/matriz",
            icon: "mdi-chart-arc",
          },
          {
            title: "Matriz de Riscos",
            route: "/riscos",
            icon: "mdi-alert-circle-outline",
          },
          {
            title: "Mapeamento de Dados",
            route: "/reports/data-find",
            icon: "mdi-database-search",
          },
          // {
          //   title: "Mapeamento de Dados",
          //   route: "/reports/data-find",
          //   icon: "mdi-database-search",
          // },
          {
            title: "Atividades por Titular",
            route: "/reports/activityByHolder",
            icon: "mdi-file-document-edit-outline",
          },
          {
            title: "Mapeamento de Dados por Titular",
            route: "/reports/dataMappingByHolder",
            icon: "mdi-folder-account",
          },
          {
            title: "Mapa Mental",
            route: "/reports/mental-map",
            icon: "mdi-file-table",
          },
          // {
          //   title: "Novo Mapa Mental",
          //   route: "/reports/old-mental-map",
          //   icon: "mdi-file-table",
          // },
        ],
        level: [0, 1, 2],
      },

      {
        name: "Solicitações",
        icon: "mdi mdi-account-details",
        list: [
          {
            title: "Solicitacao de Titulares",
            route: "/solicitacao-titulares",
            icon: "mdi mdi-account-details-outline",
          },
        ],
        level: [0, 1, 2],
      },

      {
        name: "Incidentes",
        icon: "mdi mdi-shield-alert-outline",
        list: [
          {
            title: "Incidentes NPD",
            route: "/incidentesNPD",
            icon: "mdi mdi-shield-alert",
          },
          {
            title: "Plano de Segurança",
            route: "/plan-action-security",
            icon: "mdi mdi-shield-lock",
          },
          {
            title: "Comunicação",
            route: "/communication-to-authorities",
            // icon: "mdi mdi-comment-text-multiple-outline",
            icon: "mdi mdi-email-arrow-right-outline",
          },
        ],
        level: [0, 1, 2],
      },

      {
        name: "Configurações",
        icon: "mdi-cog",
        list: [
          {
            title: "Textos Introdutórios",
            route: "/text",
            icon: "mdi-file-document-outline",
          },
          {
            title: "Perfil",
            route: "/profile",
            icon: "mdi-home-account",
          },
          {
            title: "Empresa",
            route: "/company-profile",
            icon: "mdi-office-building-outline",
          },
        ],
        level: [0, 1, 2],
      },

      {
        name: "Assinaturas",
        icon: "mdi-credit-card-outline",
        list: [
          {
            title: "Configurações",
            route: "/subscription/config",
            icon: "mdi-credit-card-settings-outline",
          },
          {
            title: "Empresas",
            route: "/subscription/company",
            icon: "mdi-credit-card-refund",
          },
        ],
        level: [1],
      },

      {
        name: "Foundation",
        icon: "mdi-dresser-outline",
        list: [
          {
            title: "Empresas",
            route: "/company",
            icon: "mdi-office-building-outline",
          },
          { title: "Usuários", route: "/user", icon: "mdi-account" },
          { title: "Grupos", route: "/group", icon: "mdi-group" },
          { title: "Rotas", route: "/route", icon: "mdi-routes" },
        ],
        level: [1],
      },
    ],
    menus: [],
    menuRules: [],
  }),
  methods: {
    goTo(route) {
      this.$router.push({ name: route })
    },
    logoff() {
      delete axios.defaults.headers.common["Authorization"]
      localStorage.clear()

      this.$store.commit("setUser", null)
      this.$router.push({ path: "/login" })
    },
    checkMenu() {
      if (!this.user) {
        return []
      }
      if (!this.user.groups) {
        return []
      }
      const groups = this.user.groups.map((el) => el.idGroup)
      const menu = []
      this.menu2.forEach((element) => {
        let includes = false
        for (let i = 0; i < groups.length; i++) {
          const g = groups[i]
          if (element.level.includes(g)) {
            includes = true
          }
        }
        if (includes) {
          menu.push(element)
        }
      })
      return menu
    },
  },
}
</script>

<style>
/* .v-navigation-drawer__border {
  display: none;
} */
</style>
