import Vue from 'vue'
import VueRouter from 'vue-router'
import { publicPath } from '../../vue.config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/general/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/general/Login'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/general//Register'),
  },
  {
    path: '/rescue',
    name: 'Rescue',
    component: () => import('@/views/general/Rescue'),
  },
  {
    path: '/rescuePassword/:token',
    name: 'RescuePassword',
    component: () => import('@/views/general/RescuePassword'),
  },
  {
    path: '/politica-de-privacidade',
    name: 'Politica de privacidade',
    component: () => import('@/views/general//PoliticaPrivacidade'),
  },
  {
    path: '/termos-de-uso',
    name: 'Termos de uso',
    component: () => import('@/views/general//TermosUso'),
  },

  /* DADOS */
  {
    path: '/data',
    name: 'Dado',
    component: () => import('@/views/crud/Dado'),
  },
  {
    path: '/data-holders',
    name: 'GrupoDados',
    component: () => import('@/views/crud/GrupoDados'),
  },
  {
    path: '/data-holders/:id/data',
    name: 'Titulares de Dados',
    component: () => import('@/views/crud/GrupoDadosTitulares'),
  },
  {
    path: '/datasensitivity',
    name: 'SensibilidadeDados',
    component: () => import('@/views/crud/SensibilidadeDados'),
  },

  /* EMPRESA */
  {
    path: '/sector',
    name: 'Setores',
    component: () => import('@/views/crud/Setores'),
  },
  {
    path: '/position',
    name: 'Cargos',
    component: () => import('@/views/crud/Cargos'),
  },
  {
    path: '/people',
    name: 'Pessoas',
    component: () => import('@/views/crud/Pessoas'),
  },
  {
    path: '/fornecedores',
    name: 'Fornecedores',
    component: () => import('@/views/crud/Fornecedores'),
  },
  {
    path: '/fornecedores/:id/supplier-data',
    name: 'FornecedoresDados',
    component: () => import('@/views/crud/FornecedoresDados'),
  },
  {
    path: '/fornecedores/:id/supplier-software',
    name: 'FornecedoresSoftware',
    component: () => import('@/views/crud/FornecedoresSoftware'),
  },
  {
    path: '/software',
    name: 'Software',
    component: () => import('@/views/crud/Software'),
  },
  {
    path: '/local',
    name: 'Locais',
    component: () => import('@/views/crud/Locais'),
  },

  /* PROCESSOS */
  {
    path: '/process',
    name: 'Atividades',
    component: () => import('@/views/crud/Atividades'),
  },
  {
    path: '/process/:id/step',
    name: 'ProcessoEtapa',
    component: () => import('@/views/crud/ProcessoEtapa'),
  },
  {
    path: '/vulnerability',
    name: 'Vulnerabilidades',
    component: () => import('@/views/crud/Vulnerabilidades'),
  },
  {
    path: '/plan',
    name: 'Planos',
    component: () => import('@/views/crud/Planos'),
  },
  {
    path: '/plan/:id/steps',
    name: 'Etapas',
    component: () => import('@/views/crud/PlanosEtapas'), 
  },


  /* INCIDENTES */
  {
    path: '/incidentesNPD',
    name: 'Incidentes',
    component: () => import('@/views/crud/IncidentesNPD'),
  },
  {
    path: '/incidentesNPD/:id/affected-holders',
    name: 'Titulares Afetados',
    component: () => import('@/views/crud/TitularesAfetados'),
  },
  {
    path: '/incidentesNPD/:id/mitigation',
    name: 'Mitigation',
    component: () => import('@/views/crud/Mitigacao'),
  },
  {
    path: '/plan-action-security',
    name: 'Plano de Açao de Segurança',
    component: () => import('@/views/crud/PlanosAcao'), 
  },
  {
    path: '/plan-action-security/:id/activity',
    name: 'PlanoAcaoAtividade',
    component: () => import('@/views/crud/PlanosAcaoAtividade'), 
  },
  {
    path: '/plan-action-security/:id/resource',
    name: 'PlanoAcaoRecurso',
    component: () => import('@/views/crud/PlanosAcaoRecurso'), 
  },
  {
    path: '/communication-to-authorities',
    name: 'Comunicacao',
    component: () => import('@/views/crud/Comunicacao'), 
  },
  
  /* Cadastros */

  
  
  // {
  //   path: '/activity',
  //   name: 'Atividades',
  //   component: () => import('@/views/crud/Atividades'),
  // },
  
  
  
  {
    path: '/text',
    name: 'Textos',
    component: () => import('@/views/crud/Textos'),
  },
  {
    path: '/privacity',
    name: 'Privacidade',
    component: () => import('@/views/general/Privacity'),
  },

  /*
  //Perfil antigo
  {
    path: '/profile',
    name: 'Perfil',
    component: () => import('@/views/crud/Profile'),
  },

  {
    path: '/company-profile',
    name: 'Company profile',
    component: () => import('@/views/crud/CompanyProfile'),
  },
  */

  {
    path: '/profile',
    name: 'Perfil',
    component: () => import('@/views/crud/Perfil'),
  },
  {
    path: '/company-profile',
    name: 'Empresa',
    component: () => import('@/views/crud/Empresa'),
  },

  /* Solicitações */
  {
    path: '/solicitacao-titulares',
    name: 'SolicitacaoTitulares',
    component: () => import('@/views/crud/SolicitacaoTitulares'),
  },
  {
    path: '/solicitacao-titulares/:id/SolicitacaoTitulares',
    name: 'SolicitacaoTitularesPai',
    component: () => import('@/views/crud/SolicitacaoTitularesPai'),
  },
  /* Reports */
  {
    path: '/reports/general',
    name: 'Report General',
    component: () => import('@/views/reports/General'),
  },
  {
    path: '/reports/company-organogram',
    name: 'Company Organogram',
    component: () => import('@/views/reports/CompanyOrganogram'),
  },
  {
    path: '/reports/matriz',
    name: 'Matriz Riscos',
    component: () => import('@/views/reports/MatrizRiscos'),
  },
  {
    path: '/reports/data-find',
    name: 'DataFind',
    component: () => import('@/views/reports/DataFind.vue'),
  },
  {
    path: '/reports/activityByHolder',
    name: 'Atividades por Titular',
    component: () => import('@/views/reports/AtividadesPorTitular.vue'),
  },
  {
    path: '/reports/dataMappingByHolder',
    name: 'Mapeamento de Dados por Titular',
    component: () => import('@/views/reports/MapeamentoDeDadosPorTitular.vue'),
  },
  // {
  //   path: '/reports/old-mental-map',
  //   name: 'OldMentalMap',
  //   component: () => import('@/views/reports/OldMentalMap.vue'),
  // },
  {
    path: '/reports/mental-map',
    name: 'MentalMap',
    component: () => import('@/views/reports/MentalMap.vue'),
  },

  /* Acompanhamentos */
  {
    path: '/riscos',
    name: 'Riscos',
    component: () => import('@/views/reports/Riscos'),
  },
  /* Fundação */
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/foundation/User'),
  },
  {
    path: '/group',
    name: 'Group',
    component: () => import('@/views/foundation/Group'),
  },
  {
    path: '/route',
    name: 'Route',
    component: () => import('@/views/foundation/Route'),
  },
  {
    path: '/company',
    name: 'Empresas',
    component: () => import('@/views/foundation/Empresas'),
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import('@/views/foundation/Menu'),
  },
 
  /* Assinaturas */
  {
    path: '/subscription/config',
    name: 'subscription config',
    component: () => import('@/views/subscription/config'),
  },
  {
    path: '/subscription/company',
    name: 'subscription company',
    component: () => import('@/views/subscription/company'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: publicPath,
  routes,
})

export default router