import axios from 'axios'
const success = (res) => res

const error = (err) => {
  if (err.response.status === 403) {
    localStorage.removeItem('user')
    window.location = '/login'
  } else {
    return Promise.reject(err)
  }
}

axios.interceptors.response.use(success, error)
