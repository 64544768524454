<template>
  <v-footer cliped-left dense color="transparent">
    <div style="width: 100%;" class="text-right caption">
      <i>SoftwareSul Dev &copy; 2019 - {{ new Date().getFullYear() }} </i>
    </div>
  </v-footer>
</template>

<script>
import { baseApiUrl } from '@/global'
export default {
  computed: {
    api() {
      return baseApiUrl
    },
  },
}
</script>

<style></style>
