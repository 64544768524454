<template>
  <div>
    <v-bottom-sheet v-model="sheet" v-if="$route.path === '/login'">
      <v-sheet>
        <v-container fluid>
          <v-row align="center">
            <v-col md="6" cols="12">
              <div class="py-3">
                Nós coletamos cookies para oferecer um serviço personalizado. Utilize as opções abaixo para configurar
                suas preferências
                quanto à coleta de cookies.
              </div>
            </v-col>
            <v-col md="6" cols="12" class="text-right">
              <v-btn color="amber" class="mx-1" dark @click="acceptLgpd()" depressed>
                Rejeitar
              </v-btn>
              <v-btn color="blue" class="mx-1" dark @click="declineLgpd()" depressed>
                Aceitar
              </v-btn>
              <v-btn color="green" text class="mx-1" dark @click="privacity()">Políticas de Privacidade
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-bottom-sheet>

    <div class="button-overall" v-if="!sheet && $route.path === '/login'">
      <v-fab-transition>
        <v-btn large dark bottom left class="v-btn--example" @click="sheet = true">
          Política de Cookies
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
export default {

  // name: 'App',
  data: () => ({
    sheet: null,
  }),
  methods: {
    privacity(){
      const politicaPrivacidadeURL = this.$router.resolve({ path: '/politica-de-privacidade' }).href;
      window.open(politicaPrivacidadeURL, '_blank');
    },
    start() {
      const lgpd = localStorage.getItem('LGPD')
      if (!lgpd) {
        this.sheet = true
      }
    },
    acceptLgpd() {
      const lgpd = {
        accept: true,
      }
      localStorage.setItem('LGPD', JSON.stringify(lgpd))
      this.sheet = false
    },
    declineLgpd() {
      const lgpd = {
        accept: false,
      }
      localStorage.setItem('LGPD', JSON.stringify(lgpd))
      this.sheet = false
    },
  },
  mounted() {
    this.start()
  },
}
</script>

<style></style>
